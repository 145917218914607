import { environment } from './environments/environment';

export const appName = 'Blink Check';
export const appVersion = '2.5.2.3195-hotfix-check-2.5.2';


export const logLevel = environment.production ? 'warn' : 'debug';
export const checkSettings = {
  nonRequiredItems: ['Text', 'Header', 'Picture', 'Link', 'Video', 'PreviewButton']
};

